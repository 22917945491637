<template v-if="ProfileData">
  <b-card
    class="card-profile"
    img-bottom
    v-bind:style="gradientColorBackground()"
  >
    <div class="position-relative">
      <div class="profile-img-container d-flex">
        <div class="profile-image-wrapper">
          <div
          >
            <b-avatar size="150" v-bind:src="'data:image/jpeg;base64,'+userData.avatar" />
          </div>
        </div>
        <div class="profile-title" style="margin-left: -120px; margin-top: 10px">
          <h2 class="text-white" style="margin-left: 140px; margin-top: 40px">
            {{ employeeData.fullName }}
          </h2>
          <p class="text-white text-left" style="margin-left: 150px">
            {{ userStatus(employeeData.projectRole) }}
          </p>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <b-button v-b-modal.modal-lg>
        {{ $t("message.profile.edit") }}
        <feather-icon icon="EditIcon" />
      </b-button>
    </div>

    <b-modal
      id="modal-lg"
      centered
      size="lg"
      :title="$t('message.profile.editProfile')"
      @ok="saveData()"
      :ok-title="$t('message.profile.save')"
      ok-only
    >
      <b-tabs>
        <b-tab active>
          <template #title>
            <feather-icon icon="UserIcon" />
            <span>{{ $t("message.profile.description") }}</span>
          </template>
          <b-form>
            <b-row>
              <b-col cols="12" md="12">
                <b-form-group :label="$t('message.profile.about')">
                  <b-form-textarea v-model="about" rows="6" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12">
                <b-form-group :label="$t('message.profile.academic')">
                  <b-form-input v-model="academicEducation" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6" md="6">
                <b-form-group label="Linkedin">
                  <b-form-input v-model="linkedin" />
                </b-form-group>
              </b-col>
              <b-col cols="6" md="6">
                <b-form-group label="Duolingo">
                  <b-form-input v-model="duolingo" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12">
                <b-form-group :label="$t('message.profile.background')">
                  <b-card v-bind:style="gradientColorBackgroundShow()"></b-card>
                  Selecionar cores
                  <b-input-group class="align-items-center">
                    <input
                      class="color1"
                      type="color"
                      name="color1"
                      v-model="color1Show"
                      @input="gradientColorBackgroundShow()"
                    />
                    <input
                      class="color2"
                      type="color"
                      name="color2"
                      v-model="color2Show"
                      @input="gradientColorBackgroundShow()"
                    />
                    <input
                      class="color3"
                      type="color"
                      name="color3"
                      v-model="color3Show"
                      @input="gradientColorBackgroundShow()"
                    />
                    <b-button
                      size="sm"
                      class="ml-1"
                      @click="randomGradientShow()"
                      >{{ $t("message.profile.random") }}</b-button
                    >
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="BriefcaseIcon" />
            <span>{{ $t("message.profile.skills") }}</span>
          </template>

          <b-card-text>
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <template v-slot:label>
                      <b> Java:</b> {{ skillJava }}%
                    </template>
                    <input
                      class="slider"
                      v-model="skillJava"
                      type="range"
                      min="0"
                      max="100"
                      step="1"
                      v-bind:style="gradientColorProgressBarJava()"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <template v-slot:label>
                      <b> Spring Boot:</b> {{ skillSpringBoot }}%
                    </template>
                    <input
                      class="slider"
                      v-model="skillSpringBoot"
                      type="range"
                      min="0"
                      max="100"
                      step="1"
                      v-bind:style="gradientColorProgressBarSpring()"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <template v-slot:label>
                      <b> MongoDb:</b> {{ skillMongoDb }}%
                    </template>
                    <input
                      class="slider"
                      v-model="skillMongoDb"
                      type="range"
                      min="0"
                      max="100"
                      step="1"
                      v-bind:style="gradientColorProgressBarMongo()"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <template v-slot:label>
                      <b> Git:</b> {{ skillGit }}%
                    </template>
                    <input
                      class="slider"
                      v-model="skillGit"
                      type="range"
                      min="0"
                      max="100"
                      step="1"
                      v-bind:style="gradientColorProgressBarGit()"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <template v-slot:label>
                      <b> Vue js:</b> {{ skillVuejs }}%
                    </template>
                    <input
                      class="slider"
                      v-model="skillVuejs"
                      type="range"
                      min="0"
                      max="100"
                      step="1"
                      v-bind:style="gradientColorProgressBarVue()"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <template v-slot:label>
                      <b> Javascript:</b> {{ skillJavascript }}%
                    </template>
                    <input
                      class="slider"
                      v-model="skillJavascript"
                      type="range"
                      min="0"
                      max="100"
                      step="1"
                      v-bind:style="gradientColorProgressBarJavascript()"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <template v-slot:label>
                      <b> HTML:</b> {{ skillHtml }}%
                    </template>
                    <input
                      class="slider"
                      v-model="skillHtml"
                      type="range"
                      min="0"
                      max="100"
                      step="1"
                      v-bind:style="gradientColorProgressBarHtml()"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <template v-slot:label>
                      <b> CSS:</b> {{ skillCss }}%
                    </template>
                    <input
                      class="slider"
                      v-model="skillCss"
                      type="range"
                      min="0"
                      max="100"
                      step="1"
                      v-bind:style="gradientColorProgressBarCss()"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="12">
                  <b-form-group :label="$t('message.profile.otherSkills')">
                    <b-form-tags
                      v-model="skillOthers"
                      placeholder=""
                      addButtonText="Add"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-modal>
  </b-card>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BImg,
  BNavbar,
  BNavbarToggle,
  BCollapse,
  BTabs,
  BNavItem,
  BButton,
  BAvatar,
  BAvatarGroup,
  BModal,
  VBModal,
  BForm,
  BFormGroup,
  BCol,
  BRow,
  BFormInput,
  BCardText,
  BFormTextarea,
  BTab,
  BFormTags,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BFormCheckbox,
} from "bootstrap-vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import { avatarText } from "@core/utils/filter";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

export default {
  components: {
    BCard,
    BTabs,
    BButton,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BImg,
    BAvatar,
    BAvatarGroup,
    BDropdown,
    BDropdownItem,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BFormGroup,
    BCol,
    BRow,
    BCardText,
    BFormTextarea,
    BTabs,
    BTab,
    BFormCheckbox,
    BFormTags,
    BInputGroup,
  },

  directives: {
    "b-modal": VBModal,
    Ripple,
  },

  data() {
    return {
      employeeData: store.state.user.employeeData.data,
      userData: store.state.user.userData,
      profileData: {},
      avatarText,

      skillJava: 0,
      skillSpringBoot: 0,
      skillMongoDb: 0,
      skillGit: 0,
      skillVuejs: 0,
      skillJavascript: 0,
      skillCss: 0,
      skillHtml: 0,

      skillOthers: [],

      about: "",
      academicEducation: "",
      linkedin: "",
      duolingo: "",

      color1: "#D5CEDC",
      color2: "#17F082",
      color3: "#17E082",

      color1Show: "#D5CEDC",
      color2Show: "#17F082",
      color3Show: "#17E082",
    };
  },

  async created() {
    await this.loadProfileData();
  },

  methods: {
    gradientColorBackground() {
      return `background: linear-gradient(45deg, ${this.color1}, ${this.color2}, ${this.color3})`;
    },

    gradientColorBackgroundShow() {
      return `background: linear-gradient(45deg, ${this.color1Show}, ${this.color2Show}, ${this.color3Show})`;
    },

    randomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },

    randomGradient() {
      this.color1 = this.randomColor();
      this.color2 = this.randomColor();
      this.color3 = this.randomColor();
      this.gradientColorBackground();
    },

    randomGradientShow() {
      this.color1Show = this.randomColor();
      this.color2Show = this.randomColor();
      this.color3Show = this.randomColor();
      this.gradientColorBackground();
    },

    async loadProfileData() {
      await axios
        .get(`${URL_API}profile/${this.userData.id}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          if (response.data != "") {
            this.profileData = response.data;
          }

          if (response.data != "") {
            if (this.profileData.skillJava > 0) {
              this.skillJava = this.profileData.skillJava;
            } else {
              this.skillJava = 0;
            }

            if (this.profileData.skillSpringBoot > 0) {
              this.skillSpringBoot = this.profileData.skillSpringBoot;
            } else {
              this.skillSpringBoot = 0;
            }

            if (this.profileData.skillMongoDb > 0) {
              this.skillMongoDb = this.profileData.skillMongoDb;
            } else {
              this.skillMongoDb = 0;
            }

            if (this.profileData.skillGit > 0) {
              this.skillGit = this.profileData.skillGit;
            } else {
              this.skillGit = 0;
            }

            if (this.profileData.skillVuejs > 0) {
              this.skillVuejs = this.profileData.skillVuejs;
            } else {
              this.skillVuejs = 0;
            }

            if (this.profileData.skillJavascript > 0) {
              this.skillJavascript = this.profileData.skillJavascript;
            } else {
              this.skillJavascript = 0;
            }

            if (this.profileData.skillCss > 0) {
              this.skillCss = this.profileData.skillCss;
            } else {
              this.skillCss = 0;
            }

            if (this.profileData.skillHtml > 0) {
              this.skillHtml = this.profileData.skillHtml;
            } else {
              this.skillHtml = 0;
            }

            if (this.profileData.skillOthers) {
              if (this.profileData.skillOthers.length > 0) {
                this.skillOthers = this.profileData.skillOthers;
              }
            } else {
              this.skillOthers = [];
            }

            if (this.profileData.about) {
              this.about = this.profileData.about;
            } else {
              this.about = "";
            }

            if (this.profileData.academicEducation) {
              this.academicEducation = this.profileData.academicEducation;
            } else {
              this.academicEducation = "";
            }

            if (this.profileData.linkedin) {
              this.linkedin = this.profileData.linkedin;
            } else {
              this.linkedin = "";
            }

            if (this.profileData.duolingo) {
              this.duolingo = this.profileData.duolingo;
            } else {
              this.duolingo = "";
            }

            if (this.profileData.backgroundProfile) {
              this.color1 = this.profileData.backgroundProfile[0];
              this.color2 = this.profileData.backgroundProfile[1];
              this.color3 = this.profileData.backgroundProfile[2];
              this.color1Show = this.profileData.backgroundProfile[0];
              this.color2Show = this.profileData.backgroundProfile[1];
              this.color3Show = this.profileData.backgroundProfile[2];
            }
          }
        });
    },

    saveData() {
      this.profileData.userId = this.userData.id;

      this.profileData.skillJava = this.skillJava;
      this.profileData.skillSpringBoot = this.skillSpringBoot;
      this.profileData.skillMongoDb = this.skillMongoDb;
      this.profileData.skillGit = this.skillGit;
      this.profileData.skillVuejs = this.skillVuejs;
      this.profileData.skillJavascript = this.skillJavascript;
      this.profileData.skillCss = this.skillCss;
      this.profileData.skillHtml = this.skillHtml;
      this.profileData.skillOthers = this.skillOthers;
      this.profileData.about = this.about;
      this.profileData.academicEducation = this.academicEducation;
      this.profileData.linkedin = this.linkedin;
      this.profileData.duolingo = this.duolingo;

      var colorList = [];
      colorList.push(this.color1Show);
      colorList.push(this.color2Show);
      colorList.push(this.color3Show);
      this.profileData.backgroundProfile = colorList;

      if (this.profileData.id === undefined) {
        axios({
          method: "post",
          url: `${URL_API}profile`,
          headers: getHeader(this.userData),
          data: this.profileData,
        }).then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.change"),
              icon: "CheckIcon",
              variant: "success",
              text: this.$i18n.t("message.toast.updatedB"),
            },
          });
          this.loadProfileData();
          this.$root.$emit("profileUpdate", this.profileData);
        });
      } else {
        axios({
          method: "put",
          url: `${URL_API}profile/${this.userData.id}`,
          headers: getHeader(this.userData),
          data: this.profileData,
        }).then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.change"),
              icon: "CheckIcon",
              variant: "success",
              text: this.$i18n.t("message.toast.updatedB"),
            },
          });
          this.loadProfileData();
          this.$root.$emit("profileUpdate", this.profileData);
        });
      }
    },

    gradientColorProgressBarJava() {
      return `background: linear-gradient(90deg, currentColor ${this.skillJava}%, rgba(0,0,0,0.1) ${this.skillJava}%); animationDelay: -${this.skillJava}s; !important`;
    },

    gradientColorProgressBarSpring() {
      return `background: linear-gradient(90deg, currentColor ${this.skillSpringBoot}%, rgba(0,0,0,0.1) ${this.skillSpringBoot}%); animationDelay: -${this.skillSpringBoot}s; !important`;
    },

    gradientColorProgressBarMongo() {
      return `background: linear-gradient(90deg, currentColor ${this.skillMongoDb}%, rgba(0,0,0,0.1) ${this.skillMongoDb}%); animationDelay: -${this.skillMongoDb}s; !important`;
    },

    gradientColorProgressBarGit() {
      return `background: linear-gradient(90deg, currentColor ${this.skillGit}%, rgba(0,0,0,0.1) ${this.skillGit}%); animationDelay: -${this.skillGit}s; !important`;
    },

    gradientColorProgressBarVue() {
      return `background: linear-gradient(90deg, currentColor ${this.skillVuejs}%, rgba(0,0,0,0.1) ${this.skillVuejs}%); animationDelay: -${this.skillVuejs}s; !important`;
    },

    gradientColorProgressBarJavascript() {
      return `background: linear-gradient(90deg, currentColor ${this.skillJavascript}%, rgba(0,0,0,0.1) ${this.skillJavascript}%); animationDelay: -${this.skillJavascript}s; !important`;
    },

    gradientColorProgressBarHtml() {
      return `background: linear-gradient(90deg, currentColor ${this.skillHtml}%, rgba(0,0,0,0.1) ${this.skillHtml}%); animationDelay: -${this.skillHtml}s; !important`;
    },

    gradientColorProgressBarCss() {
      return `background: linear-gradient(90deg, currentColor ${this.skillCss}%, rgba(0,0,0,0.1) ${this.skillCss}%); animationDelay: -${this.skillCss}s; !important`;
    },

    getColLabel(colName) {
      return this.$i18n.t("message.profile." + colName);
    },

    userStatus(role) {
      if (role == "PROGRAMMER") {
        return this.getColLabel("programmer");
      }
      if (role == "TESTER") {
        return this.getColLabel("tester");
      }
      if (role == "MANAGER") {
        return this.getColLabel("manager");
      }
      if (role == "ANALYST") {
        return this.getColLabel("analyst");
      }
      if (role == "ARCHITECT") {
        return this.getColLabel("architect");
      }
      if (role == "TRAINEE") {
        return this.getColLabel("trainee");
      }
      if (role == "UNDEFINED") {
        return this.getColLabel("undefined");
      }
    },
  },
  directives: {
    Ripple,
  },
  props: {
    headerData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style scoped>
#user-profile .profile-header {
  overflow: visible;
}

.avatar {
  margin-bottom: 1.25rem;
}

.dropdown.b-dropdown {
  margin-right: 10px;
}

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  outline: none;
  animation: color-range 100s linear forwards paused;
  background: linear-gradient(90deg, currentColor 50%, rgba(0, 0, 0, 0.1) 50%);
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #313a46;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04aa6d;
  cursor: pointer;
}

@keyframes color-range {
  0% {
    color: #f57070;
  }
  50% {
    color: #f5f570;
  }
  100% {
    color: #70f570;
  }
}
</style>
