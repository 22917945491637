<template v-if="profileData">
  <div v-if="Object.keys(profileData).length" id="user-profile">
    <profile-header :header-data="profileData.header" />
    <!-- profile info  -->
    <section id="profile-info">
      <b-row>
        <b-col cols="3" order="2" order-lg="2">
          <ProfileAbout />
          <ProfileSkills />
        </b-col>
        <b-col lg="9" cols="10" order="2">
          <ProfilePolls />
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import ProfileAbout from "./ProfileAbout.vue";
import ProfilePolls from "./ProfilePolls.vue";
import ProfileHeader from "./ProfileHeader.vue";
import ProfileSkills from "./ProfileSkills.vue";
/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,

    ProfileAbout,
    ProfilePolls,
    ProfileHeader,
    ProfileSkills,
  },
  data() {
    return {
      profileData: {},
    };
  },

  created() {
    this.$http.get("/profile/data").then((res) => {
      this.profileData = res.data;
    });
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-profile.scss";
</style>
