<template>
  <b-card>
    <!-- about -->
    <h6>{{ $t("message.profile.about") }}</h6>
    <p>{{ profileData.about }}</p>
    <h6>{{ $t("message.profile.academic") }}</h6>
    <p>{{ profileData.academicEducation }}</p>
    <h6>Linkedin</h6>
    <p>
      <a :href="profileData.linkedin">{{ profileData.linkedin }}</a>
    </p>
    <h6>Duolingo</h6>
    <a :href="profileData.duolingo">{{ profileData.duolingo }}</a>
  </b-card>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import store from "@/store";

export default {
  components: {
    BCard,
    BCardText,
  },

  data() {
    return {
      profileData: {},
      employeeData: store.state.user.employeeData.data,
      userData: store.state.user.userData,
    };
  },

  mounted() {
    this.$root.$on("profileUpdate", (profileData) => {
      this.profileData = profileData;
    });
  },

  async created() {
    await this.loadProfileData();
  },

  methods: {
    async loadProfileData() {
      await axios
        .get(`${URL_API}profile/${this.userData.id}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          if (response.data != "") {
            this.profileData = response.data;
          }
        });
    },
  },
};
</script>
