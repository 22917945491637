<template>
  <b-card>
    <!-- about -->
    <div>
      <h6>Java</h6>
      <div class="demo-vertical-spacing">
        <div class="progress-wrapper">
          <b-card-text class="mb-0"> </b-card-text>
          <b-progress max="100">
            <b-progress-bar
              :value="profileData.skillJava"
              :label="`${profileData.skillJava}%`"
              show-value
            ></b-progress-bar>
          </b-progress>
        </div>
      </div>
    </div>
    <br />
    <div>
      <h6>Spring Boot</h6>
      <div class="demo-vertical-spacing">
        <div class="progress-wrapper">
          <b-card-text class="mb-0"> </b-card-text>
          <b-progress max="100">
            <b-progress-bar
              :value="profileData.skillSpringBoot"
              :label="`${profileData.skillSpringBoot}%`"
              show-value
            ></b-progress-bar>
          </b-progress>
        </div>
      </div>
    </div>
    <br />
    <div>
      <h6>MongoDb</h6>
      <div class="demo-vertical-spacing">
        <div class="progress-wrapper">
          <b-card-text class="mb-0"> </b-card-text>
          <b-progress max="100">
            <b-progress-bar
              :value="profileData.skillMongoDb"
              :label="`${profileData.skillMongoDb}%`"
              show-value
            ></b-progress-bar>
          </b-progress>
        </div>
      </div>
    </div>
    <br />
    <div>
      <h6>Git</h6>
      <div class="demo-vertical-spacing">
        <div class="progress-wrapper">
          <b-card-text class="mb-0"> </b-card-text>
          <b-progress max="100">
            <b-progress-bar
              :value="profileData.skillGit"
              :label="`${profileData.skillGit}%`"
              show-value
            ></b-progress-bar>
          </b-progress>
        </div>
      </div>
    </div>
    <br />
    <div>
      <h6>Vue js</h6>
      <div class="demo-vertical-spacing">
        <div class="progress-wrapper">
          <b-card-text class="mb-0"> </b-card-text>
          <b-progress max="100">
            <b-progress-bar
              :value="profileData.skillVuejs"
              :label="`${profileData.skillVuejs}%`"
              show-value
            ></b-progress-bar>
          </b-progress>
        </div>
      </div>
    </div>
    <br />
    <div>
      <h6>Javascript</h6>
      <div class="demo-vertical-spacing">
        <div class="progress-wrapper">
          <b-card-text class="mb-0"> </b-card-text>
          <b-progress max="100">
            <b-progress-bar
              :value="profileData.skillJavascript"
              :label="`${profileData.skillJavascript}%`"
              show-value
            ></b-progress-bar>
          </b-progress>
        </div>
      </div>
    </div>
    <br />
    <div>
      <h6>HTML</h6>
      <div class="demo-vertical-spacing">
        <div class="progress-wrapper">
          <b-card-text class="mb-0"> </b-card-text>
          <b-progress max="100">
            <b-progress-bar
              :value="profileData.skillHtml"
              :label="`${profileData.skillHtml}%`"
              show-value
            ></b-progress-bar>
          </b-progress>
        </div>
      </div>
    </div>
    <br />
    <div>
      <h6>CSS</h6>
      <div class="demo-vertical-spacing">
        <div class="progress-wrapper">
          <b-card-text class="mb-0"> </b-card-text>
          <b-progress max="100">
            <b-progress-bar
              :value="profileData.skillCss"
              :label="`${profileData.skillCss}%`"
              show-value
            ></b-progress-bar>
          </b-progress>
        </div>
      </div>
    </div>
    <br />
    <div>
      <h6>{{ $t("message.profile.otherSkills") }}</h6>
      <div class="demo-vertical-spacing">
        <div class="progress-wrapper">
          <b-card-text class="mb-0"> </b-card-text>
          <b-form-tags
            v-model="profileData.skillOthers"
            input-id="tags-basic"
            placeholder=""
            disabled
          />
        </div>
      </div>
    </div>
    <p></p>
  </b-card>
</template>

<script>
import { BProgress, BProgressBar, BFormTags } from "bootstrap-vue";
import { BCard, BCardText } from "bootstrap-vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import store from "@/store";

export default {
  components: {
    BProgress,
    BCard,
    BCardText,
    BProgressBar,
    BFormTags,
  },

  data() {
    return {
      profileData: [],
      employeeData: store.state.user.employeeData.data,
      userData: store.state.user.userData,
    };
  },

  async created() {
    await this.loadProfileData();
  },

  mounted() {
    this.$root.$on("profileUpdate", (profileData) => {
      this.profileData = profileData;
    });
  },

  methods: {
    async loadProfileData() {
      await axios
        .get(`${URL_API}profile/${this.userData.id}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          if (response.data != "") {
            this.profileData = response.data;
          }
        });
    },
  },
};
</script>

<style></style>
